import { requests } from "../requests";

export const getUsersList = () => {
    return requests.get('/config/get_users_list');
};

export const getShowFmAgent = () => {
    return requests.get('/config/get_show_fm_agent');
};

export const getListCounterMeasuresPageData = () => {
    return requests.get('/config/get_list_countermeasures_page_data');
};

export const createImmediateMaintenance = ({ mins, server_id, servergroup_id }) => {
    return requests.post('/config/immediateMaintenance', null, { params: { mins, server_id, servergroup_id } });
};

export const createImmediateMaintenanceMultiple = ({ ids, mins }) => {
    return requests.post('/config/immediateMaintenanceMultiple', null, { params: { ids, mins } });
};

export const terminateMaintenanceSchedule = ({ maintenance_schedule_id, no_redirect }) => {
    return requests.post('/config/terminateMaintenanceSchedule', null, { params: { maintenance_schedule_id, no_redirect }});
};

export const deleteMaintenanceSchedule = ({ maintenance_schedule_id, no_redirect }) => {
    return requests.post('/config/deleteMaintenanceSchedule', null, { params: { maintenance_schedule_id, no_redirect }});
};

export const deleteApiKey = ({ id }) => {
    return requests.post('/config/deleteApiKey', null, { params: { id } });
};

export const getMergeTags = ({ ids }) => {
    return requests.get('/config/get_merge_tags', { params: { ids } });
};

export const mergeExistingTags = ({ ids, destinationId }) => {
    return requests.post('/config/mergeTagsExisting', {
        id_list: ids,
        destination_id: destinationId,
    });
};

export const mergeNewTags = ({ ids, destinationName }) => {
    return requests.post('/config/mergeTagsNew', {
        id_list: ids,
        destination_name: destinationName,
    });
};
